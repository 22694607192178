export const GTM_ID = 'GTM-K5CDTGC'

interface Window {
  dataLayer: {
    push: (obj: any) => void
  }
}
declare let window: Window

interface EventValues {
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue: number
}

const EVENT_TRIGGER = { event: 'TRIGGER_EVENT_TRACKING' }

export const pushEvent = (eventObj: EventValues) => {
  try {
    window.dataLayer.push({ ...eventObj, ...EVENT_TRIGGER })
  } catch (e: any) {
    // NOP
  }
}
