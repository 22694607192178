import { Metadata } from 'next'

const siteName = 'ドキュメントの作成・検索・レビューを効率化するならAI搭載のLAWGUE'
const defaultDescription =
  'LAWGUEは、文書作成をあらゆる面からサポート・効率化するAI を搭載したクラウド ドキュメント ワークスペースです'
export const DOMAIN = 'https://lawgue.com'

export const SEO_DEFAULT: Metadata = {
  metadataBase: new URL(DOMAIN),
  title: {
    default: siteName,
    template: `%s | LAWGUE`,
  },
  description: defaultDescription,
  openGraph: {
    title: {
      default: siteName,
      template: `%s | LAWGUE`,
    },
    description: defaultDescription,
    url: DOMAIN,
    siteName,
    locale: 'ja_JP',
    type: 'website',
    images: '/images/social/OGP.png',
  },
}

export const getMetadata = ({
  title,
  description,
  currentUrl,
  image,
}: {
  title: string
  description?: string
  currentUrl: string
  image?: string
}): Metadata => {
  return {
    title,
    description: description ?? defaultDescription,
    alternates: { canonical: `${currentUrl}` },
    openGraph: {
      ...SEO_DEFAULT.openGraph,
      title,
      type: 'article',
      description: description ?? defaultDescription,
      url: `${DOMAIN}${currentUrl}`,
      images: image ?? `${DOMAIN}/images/social/OGP.jpg`,
    },
  }
}
