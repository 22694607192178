'use client'
import '../styles/globals.scss'
import '../styles/index.css'

import React, { Suspense } from 'react'
import { TagManager } from './components/meta/TagManager'
import { Metadata } from 'next'
import { SEO_DEFAULT } from '@/utils/metadata'
import { Noto_Sans_JP, Roboto } from 'next/font/google'

const noto = Noto_Sans_JP({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans-jp',
  display: 'block',
  preload: false,
})

const roboto = Roboto({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-robot',
  display: 'swap',
})

export const metadata: Metadata = SEO_DEFAULT

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <html lang="ja">
      <body className={`${noto.className} ${roboto.variable}`}>
        <script></script>
        <Suspense>
          <TagManager />
        </Suspense>
        <div className="l-wrapper">
          <main>{children}</main>
        </div>
      </body>
    </html>
  )
}
